export const environment = {
	baseUrl: "https://api.sportyano.org/api/",
	firebaseConfig: {
		apiKey: "AIzaSyBYFpNiEFJWJmi_nj6DIJOPCYOvza_-GHw",
		authDomain: "sportyano-bbfa2.firebaseapp.com",
		projectId: "sportyano-bbfa2",
		storageBucket: "sportyano-bbfa2.appspot.com",
		messagingSenderId: "309823486163",
		appId: "1:309823486163:web:d45f71b4da85bd41d7fc26",
		measurementId: "G-YEFHDQFHX4",
	},
	recaptcha_site_key:"6Ldn_isqAAAAAHkW2gOLAP05TK9FRWrn5UaaHURx"
};
